.overlay {
    position: sticky;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
}

.right-pane:hover .overlay {
    z-index: 10;
    opacity: 1;
}
