.brand {
    font-family: 'Poppins';

    img {
        width: 100px;
        border: 3px solid #6c3ce2;
        border-radius: 50%;
        background-color: white;
    }
}

.social-icons {
    a {
        text-decoration: none;
    }

    i {
        font-size: 2rem;
    }
}

.btn-green {
    color: #000;
    text-decoration: none;
    padding: 0.4rem 1rem;
    border-radius: 5rem;
    background-color: #56ff67;

    &:hover {
        color: #000;
        background-color: #00ff1a;
    }

    &:focus {
        color: #000;
        background-color: #56ff67;
    }

    &:active {
        color: #000;
        background-color: #00c213;
    }
}
