@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css');

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    text-align: left;
    background-color: #fff;
}

.left-pane {
    background-color: #4120b7;
    border-radius: 0 0 1rem 1rem;
}

.right-pane {
    background-color: #eee;
}

@media screen and (min-width: 992px) {
    .app {
        margin: 2rem 0 2rem 0;
    }

    .left-pane {
        position: sticky;
        top: 0;
        border-radius: 1rem 1rem;
    }

    .right-pane {
        height: 100%;
        border-radius: 1rem 1rem;
    }
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: 0.5rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    text-align: center;
}
