@import 'bootstrap/scss/bootstrap';
@import '@yaireo/tagify/src/tagify';

@import 'base';
@import 'left-pane';
@import 'right-pane';
@import 'invoice-form';
@import 'spinner';

.form-control:focus {
    box-shadow: none;
}
